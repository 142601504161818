import styles from './index.module.scss';
import logo from 'src/assets/logo.png';
import twitter from 'src/assets/icons/twitter.svg';
import facebook from 'src/assets/icons/facebook.svg';
import youtube from 'src/assets/icons/youtube.svg';
import instagram from 'src/assets/icons/instagram.svg';

export function Footer() {
  const date: number = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.column}>
          <img className={styles.logo} src={logo} alt="logo" />
        </div>
        <div className={styles.column}>
          <ul className={styles.links}>
            <li>
              <a href="/">About</a>
            </li>
            <li>
              <a href="/">Press</a>
            </li>
            <li>
              <a href="/">Social</a>
            </li>
          </ul>
        </div>
        <div className={styles.column}>
          <ul className={styles.links}>
            <li>
              <a href="/">
                <img src={twitter} alt="twitter" />
                <span>Twitter</span>
              </a>
            </li>
            <li>
              <a href="/">
                <img src={facebook} alt="twitter" />
                <span>Facebook</span>
              </a>
            </li>
            <li>
              <a href="/">
                <img src={youtube} alt="twitter" />
                <span>Youtube</span>
              </a>
            </li>
            <li>
              <a href="/">
                <img src={instagram} alt="twitter" />
                <span>Instagram</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.copyright}>
        © {date} Unigamer. All Rights Reserved.
      </div>
    </footer>
  );
}
