import { Layout } from 'src/components/Layout';
import { Button } from './Button';
import { Description } from './Description';
import { Grid } from './Grid';
import { Section } from './Section';
import { Title } from './Title';
import json from 'src/assets/media/text.svg';
import enterpriseIreland from 'src/assets/media/enterpriseIreland.svg';
import newFrontiers from 'src/assets/media/newFrontiers.svg';
import leo from 'src/assets/media/leo.svg';
import iconOne from 'src/assets/icons/iconOne.svg';
import iconTwo from 'src/assets/icons/iconTwo.svg';
import iconThree from 'src/assets/icons/iconThree.svg';
import platformOne from 'src/assets/media/platformOne.svg';
import platformTwo from 'src/assets/media/platformTwo.svg';
import platformThree from 'src/assets/media/platformThree.svg';
import platformFour from 'src/assets/media/platformFour.svg';
import platformFive from 'src/assets/media/platformFive.svg';
import { Flex } from './Flex';
import { Container } from './Container';
import { Typography } from './Typography';
import styles from './Home.module.scss';

export const Home = (): JSX.Element => {
  return (
    <Layout>
      <Section>
        <Grid>
          <div>
            <Title>
              We built an API that will give you access to unique structured
              gaming data
            </Title>
            <Description>
              We provide a REST API endpoint which can be used for free for up
              to 1000 requests per month
            </Description>
            <Button url="https://swagger.unigamer.com/api-docs/">
              Get Started
            </Button>
          </div>
          <img className={styles.jsonObject} src={json} alt="json object" />
        </Grid>
      </Section>
      <Section>
        <Title centered={true}>Our Partners</Title>
        <Container>
          <Flex>
            <img src={enterpriseIreland} alt="enterprise ireland" />
            <img src={newFrontiers} alt="new frontiers" />
            <img src={leo} alt="leo" />
          </Flex>
        </Container>
      </Section>
      <Section>
        <Title centered={true}>Why Unigamer</Title>
        <Container>
          <Flex>
            <div style={{ textAlign: 'center', width: 300 }}>
              <img src={iconOne} alt="icon 1" />
              <Typography fontWeight={600} fontSize={22}>
                Test our API for FREE
              </Typography>
              <Typography fontWeight={300} fontSize={18}>
                We provide a REST API endpoint which can be used for free up to
                1000 API calls and requests per month.
              </Typography>
            </div>
            <div style={{ textAlign: 'center', width: 300 }}>
              <img src={iconTwo} alt="icon 2" />
              <Typography fontWeight={600} fontSize={22}>
                Query our Games DB
              </Typography>
              <Typography fontWeight={300} fontSize={18}>
                Our API can be used to query individual games, all games, or
                games by category, and genre.
              </Typography>
            </div>
            <div style={{ textAlign: 'center', width: 300 }}>
              <img src={iconThree} alt="icon 3" />
              <Typography fontWeight={600} fontSize={22}>
                Analyse Game Insights
              </Typography>
              <Typography fontWeight={300} fontSize={18}>
                Analyse Game insights such as Metascore, IGN score, IGBD score
                as well as Unigamer reviews and ratings.
              </Typography>
            </div>
          </Flex>
        </Container>
      </Section>
      <Section>
        <Title centered={true}>Supported Platform</Title>
        <Container>
          <Flex>
            <img src={platformOne} alt="platform 1" />
            <img src={platformTwo} alt="platform 2" />
            <img src={platformThree} alt="platform 3" />
            <img src={platformFour} alt="platform 4" />
            <img src={platformFive} alt="platform 5" />
          </Flex>
        </Container>
      </Section>
      <Section>
        <Container>
          <div className={styles.newsletter}>
            <Typography fontWeight={600} fontSize={30}>
              Sign up to Unigamer’s API for free
            </Typography>
            <Button
              url="https://share.hsforms.com/1RrTDjrvKTz-ZVkwS59ATyAc5p0m"
              secondary={true}
            >
              Sign Up
            </Button>
          </div>
        </Container>
      </Section>
    </Layout>
  );
};
