import styles from './index.module.scss';
import classnames from 'classnames';

const cx = classnames.bind(styles);

type Props = {
  children: string;
  secondary?: boolean;
  url: string;
};

export function Button({ children, secondary, url }: Props) {
  return (
    <a
      href={url}
      className={cx(styles.button, { [styles.secondary]: secondary })}
    >
      {children}
    </a>
  );
}
