import styles from './index.module.scss';
import logo from 'src/assets/logo.png';
import { Button } from 'src/components/Button';

export function Navbar() {
  // TODO replace with variable link
  return (
    <nav className={styles.navbar}>
      <img className={styles.logo} src={logo} alt="logo" />
      <Button url="https://share.hsforms.com/1RrTDjrvKTz-ZVkwS59ATyAc5p0m">
        Sign Up
      </Button>
    </nav>
  );
}
