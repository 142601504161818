import { Footer } from 'src/components/Footer';
import { Navbar } from 'src/components/Navbar';

type Props = {
  children: JSX.Element[];
};

export function Layout({ children }: Props): JSX.Element {
  return (
    <div>
      <Navbar />
      {children}
      <Footer />
    </div>
  );
}
