import styles from './index.module.scss';
import classnames from 'classnames';

const cx = classnames.bind(styles);

type Props = {
  children: string;
  centered?: boolean;
};

export function Title({ children, centered }: Props) {
  return (
    <h1 className={cx(styles.title, { [styles.centerAlign]: centered })}>
      {children}
    </h1>
  );
}
