import styles from './index.module.scss';

type Props = {
  children: string;
  fontWeight?: number;
  fontSize?: number;
};

export function Typography({ children, fontWeight, fontSize }: Props) {
  const css = {
    fontWeight: fontWeight,
    fontSize: fontSize,
  };
  return (
    <p className={styles.default} style={css}>
      {children}
    </p>
  );
}
